@use 'sass:math';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
  width: 100%; /*  Ensures the html and body take up the full viewport width */
  height: 100%; /*  Ensures the html and body take up the full viewport height*/
  margin: 0; /* Resets default browser margin*/
  padding: 0; /* Resets default browser padding*/
  scroll-behavior: smooth;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased; /*  Enhances text rendering on Webkit browsers*/
  -moz-osx-font-smoothing: grayscale; /*  Enhances text rendering on Firefox on macOS*/
}


.cms-page {
  /* h1 styles */
  h1 {
    width: 100%;
    font-size: 1.5rem; /* Mobile font size */
    line-height: 2.25rem; /* Mobile line height */
    font-weight: 600; /* Font weight */
    color: rgba(52, 56, 67, 1); /* Text color using rgba for opacity */

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      font-size: 2.375rem; /* Tablet and up font size */
      line-height: 3.563rem; /* Tablet and up line height */
    }
  }

  /* h3 styles */
  h3 {
    font-size: 1.25rem; /* Set font size */
    font-weight: 600; /* Set font weight */
    margin: 0.75rem 0; /* Set top margin */
    color: rgba(52, 56, 67, 1); /* Set text color with opacity */
  }

  /* p styles */
  p {
    --tw-text-opacity: 1;
    color: rgb(101 113 131 / var(--tw-text-opacity));
    line-height: 1.5;
    margin: 1rem 0;

    span {
      font-weight: 600;
    }
  }

  a {
    --tw-text-opacity: 1;
    color: rgb(7 88 177 / var(--tw-text-opacity))
  }
}


.bg-blur {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(35.4px);
}

.blur-shadow {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.blur-shadow-hover:hover {
  box-shadow: 0 4px 6px -1px rgba(186, 222, 255, math.div(0.1, 255)), 0 2px 4px -2px rgba(186, 222, 255, math.div(0.1, 255));
}

@media screen and (max-width: 640px) {
  .bg-blur-sm {
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;
    border-image: none;
  }
}

.custom-shadow {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  &:hover {

  }
}

cdk-accordion-item,
button {
  -webkit-tap-highlight-color: transparent;
}

.ratings-divider {
  background: linear-gradient(90deg, rgba(237, 243, 255, 0.14) 0.83%, #ECF2FF 23.15%, rgba(157, 170, 197, 0) 98.65%);;
}

.rating-text {
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.divider:before {
  content: '';
  position: absolute;
  background: #f4f7fb;
  height: 2px;
  width: 200%;
  right: 0;
  bottom: -5px
}

.unavailable {
  pointer-events: none;
  opacity: 20%;
}

.mat-dialog-container {
  padding: 0 !important;
}

/* Mobile Sidenav */
#menuToggle {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 90;
  -webkit-user-select: none;
  border-radius: 8px;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}


#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  background: #f4f7fb;
  border-radius: 8px;

  cursor: pointer;

  opacity: 0;
  z-index: 2;

  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #fff;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
  opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #333;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 125px 50px 50px;
  border-radius: 8px;

  background: #f4f7fb;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle input:checked ~ ul {
  transform: none;
}


/* Spinner */
$offset: 187;
$duration: 1.4s;


.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
  colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #DE3E35;
  }
  50% {
    stroke: #F7C223;
  }
  75% {
    stroke: #1B9A59;
  }
  100% {
    stroke: #4285F4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

/* Start Rating */
.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: 32px;
  font-family: Times; /* make sure ★ appears correctly */
  line-height: 1;
  color: #E9765D;

  &::before {
    content: '\2605\2605\2605\2605\2605';
    letter-spacing: 3px;
    stroke: #E9765D;
    stroke-width: 10;
    background: linear-gradient(90deg, #E9765D var(--percent), #fff var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px #E9765D;
  }
}

.star-left-position {
  left: calc(50% - 103.5px);
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.3s;
}

.ripple:hover {
  background: #2c78b4 radial-gradient(circle, transparent 1%, #0758B1 1%) center/15000%;
}

.ripple:active {
  background-color: #2c78b4;
  background-size: 100%;
  transition: background 0s;
}

.ripple-white:hover {
  background: #e3f0ff radial-gradient(circle, transparent 1%, #2c78b4 1%) center/15000%;
}

.ripple-white:active {
  background-color: #e3f0ff;
  background-size: 100%;
  transition: background 0s;
}

.active-arrow-tab {
  color: #004494;

  .arrow {
    width: fit-content;
    position: relative;
    margin: 0 auto;
  }
}


/* Carousel */
@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }
  95% {
    left: -300%;
  }
  98% {
    left: -300%;
  }
  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }
  97% {
    scroll-snap-align: none;
  }
  99% {
    scroll-snap-align: none;
  }
  100% {
    scroll-snap-align: center;
  }
}

ol, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.carousel {
  position: relative;
  padding-top: 75%;
  perspective: 100px;
}

.carousel__viewport {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  counter-reset: item;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.carousel__slide {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  counter-increment: item;
}

.carousel__slide:before {
  content: counter(item);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -40%, 70px);
  font-size: 2em;
}

.carousel__snapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
}

@media (prefers-reduced-motion: reduce) {
  .carousel__snapper {
    animation-name: none;
  }
}

.carousel:hover .carousel__snapper,
.carousel:focus-within .carousel__snapper {
  animation-name: none;
}

.carousel__slide input[type="radio"] {
  display: none; /* Hide the radio inputs */
}

.carousel__slide input[type="radio"]:checked ~ .carousel__slide {
  animation-play-state: paused; /* Pause the carousel animation when a radio is checked */
}

.carousel__slide input[type="radio"]:checked ~ .carousel__slide .carousel__prev,
.carousel__slide input[type="radio"]:checked ~ .carousel__slide .carousel__next {
  pointer-events: auto; /* Enable pointer events for the previous and next buttons when a radio is checked */
}

@media screen and (max-width: 767px) {
  /* Disable the animation on Android */
  .carousel__snapper {
    animation-name: none !important;
  }
}

/* basic styling */
.grid_masonry {
  margin: auto;
  justify-items: center !important;
}

.masonry-item > p {
  font-size: 14px;
  padding: 0.5rem;
  margin: 0;
}

.masonry-item > img {
  width: 100%;
  height: auto;
}

/* masonry styles */
.grid_masonry {
  --masonry-columns: 3;
  --masonry-gap: 0;
}


@media screen and (max-width: 1024px) {
  .grid_masonry {
    --masonry-columns: 2;
  }
}

@media screen and (max-width: 640px) {
  .grid_masonry {
    --masonry-columns: 1;
  }
}

/* load only if grid-template-rows: masonry is supported by the browser else use the fallback */
@supports (grid-template-rows: masonry) {
  .grid_masonry {
    display: grid;
    grid-template-columns: repeat(var(--masonry-columns), 1fr);
    grid-gap: var(--masonry-gap);
    grid-template-rows: masonry;
  }

  .grid_masonry > * {
    margin-bottom: 0;
  }
}

/* fallback */
.grid_masonry {
  column-count: var(--masonry-columns);
  column-gap: var(--masonry-gap);
}

.grid_masonry > * {
  margin-bottom: var(--masonry-gap);
  break-inside: avoid;
}

.masonry-item {
  width: 100%;
  transition: top 0.4s ease-in-out, left 0.4s ease-in-out;
}

/* Spinner */
$offset: 187;
$duration: 1.4s;


.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
  colors ($duration*4) ease-in-out infinite;
}


@keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #DE3E35;
  }
  50% {
    stroke: #F7C223;
  }
  75% {
    stroke: #1B9A59;
  }
  100% {
    stroke: #4285F4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}


.circle-shadow {
  -webkit-box-shadow: 0 170px 62px -90px rgba(168,168,168,1);
  -moz-box-shadow: 0 170px 62px -90px rgba(168,168,168,1);
  box-shadow: 0 170px 62px -90px rgba(168,168,168,1);
}
